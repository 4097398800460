import React from "react";
import { AppLogo } from "../../../ui";
import styled from "styled-components";
import { mobileSize } from "../../../../util/variables";
import { FreeTrialSegment, SegmentI } from "./FreeTrialSegment";
import PrepaymentHeroImage from "./PrepaymentHeroImage";

export const PrepaymentHero: React.FC<Omit<SegmentI, "id">> = ({
  handleRedirect,
  loading,
  priceData,
  isFamilyPlanTest,
}) => {
  return (
    <Background>
      <Container>
        <Logo>
          <AppLogo size="medium" />
        </Logo>
        <FreeTrialSegment
          whiteVersion
          id="free-trial-1"
          priceData={priceData}
          handleRedirect={handleRedirect}
          loading={loading}
          isFamilyPlanTest={isFamilyPlanTest}
        />
      </Container>
      <PrepaymentHeroImage />
    </Background>
  );
};

const Background = styled.div`
  background-color: #fafafa;
  padding: 96px 0px 40px 0px;
  @media ${mobileSize} {
    padding: 24px 0px 32px 0px;
  }
`;

const Container = styled.section`
  display: flex;
  flex-direction: column;
  /* gap: 18px; */
  max-width: 1200px;
  margin: 0 auto;
  margin-bottom: 56px;
  padding: 0 24px;
  text-align: center;

  @media ${mobileSize} {
    margin-bottom: 32px;
    text-align: left;
  }
`;

const Logo = styled.div`
  @media ${mobileSize} {
    display: none;
  }
`;
