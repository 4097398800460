import { isDevEnvironment } from "./misc";

const IS_DEV = isDevEnvironment();

const TEST_WEB_PRICE_ID = "price_1QZdSpLvyjRKGUTXTtOpuhKL";
const TEST_APP_PRICE_ID = "price_1QZdSpLvyjRKGUTXTj1xpGWz";

// These price IDs include international currencies, and can be accessed by passing
// a currency code to the cloud function(s). Key values are in USD.
export const DYNAMIC_PRICE_IDS = {
  trial: {
    161.85: "price_1PbmbWLvyjRKGUTXPsDXHKJp",
  },
  embeddables: {
    124.99: "price_1Pqn4BLvyjRKGUTXFzFNdPM4",
  },
};

export const DYNAMIC_GIFT_PRICE_IDS = {
  124.99: "price_1QICWyLvyjRKGUTXOJUpQxDs",
  99.99: "price_1QSm6ALvyjRKGUTX8WufWny5",
  // TEST ONLY
  1.0: "price_1QKMjHLvyjRKGUTXSYhZ3iFh",
};

// dev price ids
// export const DYNAMIC_PRICE_IDS = {
//   trial: {
//     161.85: "price_1NUD3KLvyjRKGUTXWMFsCyDg",
//   },
// };

export const US_PRICE_IDS = {
  trial: {
    99.99: IS_DEV ? TEST_APP_PRICE_ID : "price_1JZipULvyjRKGUTXaG2RtwSq",
    124.99: IS_DEV ? TEST_WEB_PRICE_ID : "price_1NPs3gLvyjRKGUTXkNJG5iX5",
    149.99: "price_1NToWjLvyjRKGUTXFI4a2u0x",
  },
  no_trial: {
    99.99: IS_DEV ? TEST_APP_PRICE_ID : "price_1KHDXcLvyjRKGUTXl8dDPhK4",
    124.99: IS_DEV ? TEST_WEB_PRICE_ID : "price_1NQXi2LvyjRKGUTXLzKy1evn",
    149.99: "price_1NToVrLvyjRKGUTXY5n9rrVN",
  },
};

export const US_GIFT_PRICE_IDS = {
  124.99: "price_1QICcCLvyjRKGUTXZhW05OOY",
};

// dev price ids
// export const US_PRICE_IDS = {
//   no_trial: {
//     99.99: IS_DEV ? "" : "price_1LIZdTLvyjRKGUTXiCkeJeqH",
//     124.99: IS_DEV ? "" : "price_1NUD3KLvyjRKGUTXWMFsCyDg",
//     149.99: IS_DEV ? "" : "price_1NUD3vLvyjRKGUTXzpaKdryp",
//   },
//   trial: {
//     99.99: IS_DEV ? "" : "price_1LIZdTLvyjRKGUTXiCkeJeqH",
//     124.99: IS_DEV ? "" : "price_1NUD3KLvyjRKGUTXWMFsCyDg",
//     149.99: IS_DEV ? "" : "price_1NUD3vLvyjRKGUTXzpaKdryp",
//   },
// };

export const EU_PRICE_IDS = {
  trial: {
    89.99: IS_DEV ? TEST_APP_PRICE_ID : "price_1NnjaLLvyjRKGUTXMSZnHiNz",
    112.49: IS_DEV ? TEST_WEB_PRICE_ID : "price_1Nf75SLvyjRKGUTXaE7rG09j",
  },
  no_trial: {
    89.99: IS_DEV ? TEST_APP_PRICE_ID : "price_1NnjeLLvyjRKGUTXjhCxWuJh",
    112.49: IS_DEV ? TEST_WEB_PRICE_ID : "price_1NkY1QLvyjRKGUTXVzKfGXjJ",
  },
};

export const EU_GIFT_PRICE_IDS = {
  112.49: "price_1QICwXLvyjRKGUTX3lP8Tqae",
};

export const GB_PRICE_IDS = {
  trial: {
    69.99: IS_DEV ? TEST_APP_PRICE_ID : "price_1NnjbaLvyjRKGUTXtfYE9kZ1",
    87.49: IS_DEV ? TEST_WEB_PRICE_ID : "price_1Nf789LvyjRKGUTXbBHpErVT",
  },
  no_trial: {
    69.99: IS_DEV ? TEST_APP_PRICE_ID : "price_1NnjeaLvyjRKGUTXdl0w53vq",
    87.49: IS_DEV ? TEST_WEB_PRICE_ID : "price_1NkXzvLvyjRKGUTX4zpLWzXG",
  },
};

export const GB_GIFT_PRICE_IDS = {
  87.49: "price_1QICtuLvyjRKGUTX3ds0nzFy",
};

export const CA_PRICE_IDS = {
  trial: {
    124.99: IS_DEV ? TEST_APP_PRICE_ID : "price_1Nnjc1LvyjRKGUTXO9IxzDZN",
    156.24: IS_DEV ? TEST_WEB_PRICE_ID : "price_1Nf78RLvyjRKGUTX1AlSnyog",
  },
  no_trial: {
    124.99: IS_DEV ? TEST_APP_PRICE_ID : "price_1NnjfHLvyjRKGUTXsfN0BXWq",
    156.24: IS_DEV ? TEST_WEB_PRICE_ID : "price_1NkXzKLvyjRKGUTXPUQ92cnB",
  },
};

export const CA_GIFT_PRICE_IDS = {
  156.24: "price_1QICurLvyjRKGUTXwfWbZ8mk",
};

export const AU_PRICE_IDS = {
  trial: {
    149.99: IS_DEV ? TEST_APP_PRICE_ID : "price_1NnjcTLvyjRKGUTX9DVEm5Im",
    187.49: IS_DEV ? TEST_WEB_PRICE_ID : "price_1Nf75nLvyjRKGUTXBQwDdsxa",
  },
  no_trial: {
    149.99: IS_DEV ? TEST_APP_PRICE_ID : "price_1NnjfiLvyjRKGUTXLJrv5g8T",
    187.49: IS_DEV ? TEST_WEB_PRICE_ID : "price_1NkY13LvyjRKGUTX8BV93Zxo",
  },
};

export const AU_GIFT_PRICE_IDS = {
  187.49: "price_1QICvMLvyjRKGUTXhYoz9Wsz",
};

export const NZ_PRICE_IDS = {
  trial: {
    149.99: IS_DEV ? TEST_APP_PRICE_ID : "price_1NnjcxLvyjRKGUTXzda4P8ND",
    187.49: IS_DEV ? TEST_WEB_PRICE_ID : "price_1Nf757LvyjRKGUTXlQXPkMy3",
  },
  no_trial: {
    149.99: IS_DEV ? TEST_APP_PRICE_ID : "price_1Nnjg3LvyjRKGUTXbc3rp1pG",
    187.49: IS_DEV ? TEST_WEB_PRICE_ID : "price_1NkY1wLvyjRKGUTXsq2BU9hd",
  },
};

export const NZ_GIFT_PRICE_IDS = {
  187.49: "price_1QICvpLvyjRKGUTXg5qXl5NN",
};

export const PAID_TRIAL_PRICE_IDS = {
  1: IS_DEV ? "price_1O5uDoLvyjRKGUTXHZIqck8o" : "price_1R7I5QLvyjRKGUTXNXp6YsjL",
  7: IS_DEV ? "price_1R6xpULvyjRKGUTXe4lEAyRk" : "price_1R7I5QLvyjRKGUTXV4WalvUh",
  11.41: IS_DEV ? "price_1R6xpULvyjRKGUTXVX80fEkk" : "price_1R7I5QLvyjRKGUTXjo4iJEHY",
};

export const UNIVERSAL_PRICE_IDS = {
  99.99: IS_DEV ? "price_1R6xvoLvyjRKGUTX09q5TrlP" : "price_1R7fzZLvyjRKGUTX9XoHOqlc",
  124.99: IS_DEV ? "price_1R6y2OLvyjRKGUTXVQyceDEz" : "price_1Pqn4BLvyjRKGUTXFzFNdPM4",
};
