// import {
//   REMOTE_CONFIG_BOOLEAN_KEYS,
//   REMOTE_CONFIG_STRING_KEYS,
//   getRemoteConfigBoolean,
//   getRemoteConfigString,
// } from "../firebase-remote-config";
import { trackEvent } from "./mixpanel";

const trackAcquisitionEvent = (event: string, properties: object = {}) => {
  trackEvent(event, properties, "web_acquisition");
};

// LANDING PAGE

export const web_acq_landing_page_view = async () => {
  // const variation = await getRemoteConfigString(REMOTE_CONFIG_STRING_KEYS.landingPageVariation);
  trackAcquisitionEvent("WebAcqLandingPageView", {
    // landingPageVariation: variation ?? "A",
  });
};

export const web_acq_landing_page_click = async (section: string) => {
  // const variation = await getRemoteConfigString(REMOTE_CONFIG_STRING_KEYS.landingPageVariation);
  trackAcquisitionEvent("WebAcqLandingPageClick", {
    section,
    // landingPageVariation: variation ?? "A",
  });
};

// LANDING PAGE - MODAL

export const web_acq_landing_page_modal_view = async () => {
  // const variation = await getRemoteConfigString(REMOTE_CONFIG_STRING_KEYS.landingPageVariation);
  trackAcquisitionEvent("WebAcqLandingModalView", {
    // landingPageVariation: variation ?? "A",
  });
};

export const web_acq_landing_page_modal_click = async () => {
  // const variation = await getRemoteConfigString(REMOTE_CONFIG_STRING_KEYS.landingPageVariation);
  trackAcquisitionEvent("WebAcqLandingModalClick", {
    // landingPageVariation: variation ?? "A",
  });
};

export const web_acq_landing_page_modal_closed = async () => {
  // const variation = await getRemoteConfigString(REMOTE_CONFIG_STRING_KEYS.landingPageVariation);
  trackAcquisitionEvent("WebAcqLandingModalX", {
    // landingPageVariation: variation ?? "A",
  });
};

// ONBOARDING QUESTIONS

export const web_acq_question_view = (cardID: string) => {
  trackAcquisitionEvent("Onboarding_Question_View", {
    "Card id": cardID,
  });
};

export const web_acq_question_click = (cardID: string) => {
  trackAcquisitionEvent("Onboarding_Question_Click", {
    "Card id": cardID,
  });
};

export const web_acq_submit_answer = (cardID: string, answerText?: string | string[]) => {
  trackAcquisitionEvent("Onboarding_Question_SubmitAnswer", {
    "Card id": cardID,
    "Answer text": answerText,
  });
};

// ACCOUNT CREATION / SSO

export const web_acq_create_account_view = () => {
  trackAcquisitionEvent("WebAcqCreateAccountView");
};

export type ProviderT = "email" | "google" | "apple";

export const web_acq_create_account_attempt = (Provider: ProviderT) => {
  trackAcquisitionEvent("WebAcqCreateAccountAttempt", {
    Provider,
  });
};

export const web_acq_create_account_success = (Provider: ProviderT) => {
  trackAcquisitionEvent("WebAcqCreateAccountSuccess", {
    Provider,
  });
};

export const web_acq_create_account_failure = (Provider: ProviderT) => {
  trackAcquisitionEvent("WebAcqCreateAccountFailure", {
    Provider,
  });
};

// PREPAYMENT PAGE

export const web_acq_prepayment_view = () => {
  trackAcquisitionEvent("WebAcqPrepaymentView");
};

export const web_acq_prepayment_attempt = () => {
  trackAcquisitionEvent("WebAcqPrepaymentAttempt");
};

export const web_acq_prepayment_success = () => {
  trackAcquisitionEvent("WebAcqPrepaymentSuccess");
};

export const web_acq_prepayment_failure = () => {
  trackAcquisitionEvent("WebAcqPrepaymentFailure");
};

export const web_acq_prepayment_section_view = (section: string) => {
  trackAcquisitionEvent("WebAcqPrepaymentSectionView", {
    section,
  });
};

export const web_acq_prepayment_seconds_spent = (seconds: number) => {
  trackAcquisitionEvent("WebAcqPrepaymentSecondsSpent", {
    seconds,
  });
};

// POST PAYMENT

export const web_acq_post_payment_view = () => {
  trackAcquisitionEvent("WebAcqPostpaymentView");
};

export const web_acq_post_payment_success = () => {
  trackAcquisitionEvent("WebAcqPostpaymentSuccess");
};

export const web_acq_post_payment_fail = () => {
  trackAcquisitionEvent("WebAcqPostpaymentFail");
};
