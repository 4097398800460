import axios from "../services/axios";
import { GATSBY_BE_URL } from "../util/variables";

export interface StripeCheckoutData {
  subscriptionType?: "yearly" | "discount" | "coupon" | "monthly";
  priceIds?: string[] | undefined;
  couponId?: string | undefined;
  trial?: boolean;
  email?: string;
  uid?: string;
  currency_code?: string;
}

export type SubscriptionMetadata = {
  order_type?: "gift_coupon" | "bogo" | "control";
  coupon_code?: string;
};

export type GiftPurchaseMetadata = {
  gifted_by: string;
  gifted_to: string;
  coupon_code: string;
};

// v2 stripe url payload type
export type BaseStripeUrlV2Payload = {
  priceId: string;
  email?: string;
  uid?: string;
  cancel_url: string;
  success_url: string;
  currency?: string; // the currency code
  mode: "subscription" | "payment";
  // cid?: string;
  allow_promotion_codes?: boolean;
  metadata?: Record<string, string | number>;
  payment_intent_data?: { metadata: { [key: string]: string | number } }; // can only be used in payment mode
  subscription_data?: {
    trial_period_days?: number; // minimum 1 day. If you want no trial, don't include this field
    metadata?: { [key: string]: string };
    description?: string;
    trial_from_plan?: boolean;
  };
  discounts?: Array<{ coupon?: string; promotion_code?: string }>;
};

export type PaidTrialStripeUrlPayload = Omit<BaseStripeUrlV2Payload, "priceId"> & {
  priceIds: string[];
};

// DEPRECATED: plan to remove 1/15/2025
export const getStripeCheckoutUrl = async ({
  subscriptionType,
  priceIds,
  couponId,
  trial = false,
  email,
  uid,
  currency_code,
}: StripeCheckoutData) => {
  const data: any = {};
  data.subscription_type = subscriptionType;
  data.priceIds = priceIds;
  data.cid = couponId;
  data.trial = trial;
  data.email = email;
  data.uid = uid;
  data.cancel_url = typeof window === "undefined" ? undefined : window.location.href;
  data.currency = currency_code;

  const res = await axios.post(`${GATSBY_BE_URL}/registerStripeUser`, JSON.stringify(data));
  const url = await res.data;
  return url;
};

// DEPRECATED: plan to remove 1/15/2025
export const getStripeDiscountURL = async ({
  email,
  uid,
  product_id,
  cancel_query_params,
  multiple_plans,
  currency_code,
  coupon_id,
}: {
  email: string;
  uid: string;
  product_id?: string;
  cancel_query_params?: { [key: string]: string };
  multiple_plans?: boolean;
  currency_code: string;
  coupon_id?: string;
}) => {
  const res = await axios.post(
    `${GATSBY_BE_URL}/createDiscountStripeUrl`,
    JSON.stringify({
      email,
      uid,
      product_id,
      cancel_query_params,
      multiple_plans,
      currency: currency_code,
      coupon_id,
    }),
  );
  const url = await res.data;
  return url;
};

export const getStripeProductPrice = async (priceId: string) => {
  const res = await axios.get(`${GATSBY_BE_URL}/getStripeProductPrice`, { params: { priceId } });
  // convert from cents to dollars
  return res.data / 100;
};

export const getCouponData = async (couponId: string) => {
  const res = await axios.get(`${GATSBY_BE_URL}/getCouponData`, { params: { couponId } });
  const { amount_off, percent_off, duration } = res.data;
  return {
    amount_off: amount_off ? amount_off / 100 : 99.99 * (percent_off / 100),
    duration,
  };
};

/**
 * Get's the customers payment data after completing Stripe checkout
 * @param sessionId Stripe session ID
 * @returns object: email, customer id, and subscription id
 */
export const getStripePaymentData = async (sessionId: string) => {
  try {
    const res = await axios.get(`${GATSBY_BE_URL}/getStripePaymentData`, {
      params: { session_id: sessionId },
    });
    return res.data;
  } catch (err) {
    console.error(err);
    throw new Error(`Error getting stripe payment data: ${err}`);
  }
};

// new stripe url to refactor into the rest of the codbase in refactors.ts
export const getStripeUrlV2 = async (checkoutData: BaseStripeUrlV2Payload) => {
  try {
    const res = await axios.post(`${GATSBY_BE_URL}/createStripeUrl`, JSON.stringify(checkoutData));
    return res.data;
  } catch (err) {
    console.error(err);
    throw new Error(`Error getting stripe url v2: ${err}`);
  }
};

export const getStripeUrlPaidTrial = async (checkoutData: PaidTrialStripeUrlPayload) => {
  try {
    const res = await axios.post(`${GATSBY_BE_URL}/createStripeUrlPaidTrial`, JSON.stringify(checkoutData));
    return res.data;
  } catch (err) {
    console.error(err);
    throw new Error(`Error getting stripe url v2: ${err}`);
  }
};

export const validatePromotionCode = async (coupon: string) => {
  try {
    const res = await axios.post(`${GATSBY_BE_URL}/checkPromotionCodeEndPoint`, {
      coupon,
    });
    return res.data;
  } catch (err) {
    console.error(err);
    throw new Error(`Error validating promotion code: ${err}`);
  }
};

export const retrievePromotionCodeFromCouponId = async (couponId: string) => {
  try {
    const res = await axios.post(`${GATSBY_BE_URL}/retrievePromotionCodeFromCouponId`, {
      couponId,
    });
    return res.data;
  } catch (err) {
    console.error(err);
    throw new Error(`Error retrieving promotion code from coupon id: ${err}`);
  }
};
